import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { LEAD_SOURCE_FRONT_APP, LEAD_MEDIUM_LP } from 'Consts/leads';

import { parseQueryToObject } from 'Utils/querystring';

import Logger from 'Services/Logger';

import StyledComponent from 'Components/core/StyledComponent';
import LayoutContainer from 'Components/layout/LayoutContainer';
import Form from 'Components/forms/Form';
import Input from 'Components/forms/Input';
import Checkbox from 'Components/forms/Checkbox';
import Button from 'Components/layout/Button';

export default class PublicAdeptWelcomeFormSectionComponent extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            createLead: PropTypes.func.isRequired,
        }).isRequired,
    };
    static defaultProps = {};

    state = {
        globalError: null,
        errors: {},
        isPending: false,
        isSuccess: false,
        formState: {
            name: '',
            surname: '',
            phone: '',
            email: '',
            city: '',
            promoCode: '',
            selectAll: false,
            consentsMarketingPhone: false,
            consentsMarketingEmail: false,
            consentsMarketingTerms: false,
        },
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (!prevState.formState.selectAll && this.state.formState.selectAll) {  //eslint-disable-line react/destructuring-assignment
            this.setState(prevState => ({
                formState: {
                    ...prevState.formState,
                    consentsMarketingPhone: true,
                    consentsMarketingEmail: true,
                    consentsMarketingTerms: true,
                },
            }));
        }

        if (prevState.formState.selectAll && !this.state.formState.selectAll) {  //eslint-disable-line react/destructuring-assignment
            this.setState(prevState => ({
                formState: {
                    ...prevState.formState,
                    consentsMarketingPhone: false,
                    consentsMarketingEmail: false,
                    consentsMarketingTerms: false,
                },
            }));
        }
    }

    onSubmit = () => {
        const { formState } = this.state;
        const { actions, location } = this.props;

        const queryObject = parseQueryToObject(location.search);

        this.setState({
            isPending: true,
            errors: {},
            globalError: null,
        });

        const data = {
            ...formState,
            consents: [{
                type: 'terms',
                content: 'Oświadczam, że zapoznałem/-am się z Regulaminem serwisu internetowego FitAdept.com oraz akceptuję jego treść.',
                value: formState.consentsMarketingTerms,
            }, {
                type: 'marketingEmail',
                content: 'Wyrażam zgodę na otrzymywanie informacji handlowych dotyczących towarów lub usług FitAdept na wskazany przeze mnie adres e-mail drogą elektroniczną.',
                value: formState.consentsMarketingEmail,
            }, {
                type: 'phone',
                content: 'Wyrażam zgodę na przekazywanie mi treści marketingowych FitAdept sp. z o.o. za pomocą telekomunikacyjnych urządzeń końcowych, których jestem użytkownikiem, w szczególności na podany przeze mnie numer telefonu.',
                value: formState.consentsMarketingPhone,
            }],
            medium: LEAD_MEDIUM_LP,
            source: LEAD_SOURCE_FRONT_APP,
            withSurvey: true,
        }

        return actions.createLead(data)
            .then(response => {
                this.setState({
                    isPending: false,
                    isSuccess: true,
                });
            })
            .catch(error => {
                Logger.error('[ADEPT_WELCOME] Error', error);
                this.setState({
                    isPending: false,
                    errors: error.payload.validationErrors,
                    globalError: error.payload.message,
                });
            });
    }

    onStateChange = (name, value) => this.setState(prevState => ({
        formState: {
            ...prevState.formState,
            [name]: value,
        },
    }))

    render() {
        const { formState, errors, globalError, isPending, isSuccess } = this.state;

        return (
            <StyledComponent styles={require('./styles')}>
                <div className="image-bg" />
                <LayoutContainer>
                    {isSuccess ?
                        <div className="form-section-container">
                            <div className="headline-container">
                                <h1 className="headline">
                                    Dziękujemy
                                </h1>
                                <p className="subheadline">
                                    Witaj w społeczności FitAdept. <br />
                                    W najbliższym czasie nasz konsultant skontaktuje się z Tobą.
                                </p>
                            </div>
                        </div>
                        :
                        <div className="form-section-container">
                            <div className="headline-container">
                                <div className="logo">
                                    <img src={require('Theme/images/logo-color.png')} />
                                </div>
                                <h1 className="headline">
                                    Dołącz
                                    <span className="sub">do społeczności FitAdept</span>
                                </h1>
                            </div>
                            <div className="form-container">
                                <Form
                                    data={formState}
                                    onStateChange={this.onStateChange}
                                    errors={errors}
                                    globalError={globalError}
                                    onSubmit={this.onSubmit}
                                    isPending={isPending}
                                >
                                    <Input
                                        label="Imię"
                                        name="name"
                                        placeholder="Imię"
                                        type="text"
                                        required={true}
                                    />
                                    <Input
                                        label="Nazwisko"
                                        name="surname"
                                        placeholder="Nazwisko"
                                        type="text"
                                        required={true}
                                    />
                                    <Input
                                        label="Numer telefonu"
                                        name="phone"
                                        placeholder="Numer telefonu"
                                        type="tel"
                                        required={true}
                                    />
                                    <Input
                                        label="Adres e-mail"
                                        name="email"
                                        placeholder="Adres e-mail"
                                        type="email"
                                        required={true}
                                    />
                                    <Input
                                        label="Miasto"
                                        name="city"
                                        placeholder="Miasto"
                                        type="text"
                                        required={true}
                                    />
                                    <Input
                                        label="Kod promocyjny"
                                        name="promoCode"
                                        placeholder="Kod promocyjny"
                                        type="text"
                                    />
                                    <Button
                                        className="submit-button"
                                        type="submit"
                                        icon={{ type: 'fa', source: 'fas fa-arrow-right' }}
                                        layout="fullWidth"
                                        size="large"
                                    >
                                        Dołącz
                                    </Button>

                                    <Checkbox
                                        name="selectAll"
                                        label="Zaznacz wszystkie zgody"
                                        className="select-all-checkbox"
                                    />
                                    <Checkbox
                                        name="consentsMarketingPhone"
                                        label="Wyrażam zgodę na przekazywanie mi treści marketingowych FitAdept sp. z o.o. za pomocą telekomunikacyjnych urządzeń końcowych, których jestem użytkownikiem, w szczególności na podany przeze mnie numer telefonu."
                                    />
                                    <Checkbox
                                        name="consentsMarketingEmail"
                                        label="Wyrażam zgodę na otrzymywanie informacji handlowych dotyczących towarów lub usług FitAdept na wskazany przeze mnie adres e-mail drogą elektroniczną."
                                    />
                                    <Checkbox
                                        name="consentsMarketingTerms"
                                        label="Oświadczam, że zapoznałem/-am się z <a href='https://fitadept.com/regulamin' target='_blank'>Regulaminem</a> serwisu internetowego FitAdept.com oraz akceptuję jego treść."
                                        required={true}
                                    />
                                </Form>
                            </div>
                        </div>
                    }
                </LayoutContainer>
            </StyledComponent>
        );
    }
}
